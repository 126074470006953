<template>
    <b-container fluid>

        <b-row class="mb-2">

        </b-row>

        <b-table stacked="md" class="text-center" :items="entities" :fields="tableFields">
            <template v-slot:cell(actions)="data">
                <b-link class="ml-4" :to="{ name: 'sorting-center-editor', params: {id: data.item.id}}">
                    Редактировать
                </b-link>
            </template>
        </b-table>
        <pagination :total-elements="pagination.totalElements" @change="paginationChange"/>
    </b-container>
</template>

<script>

    import Pagination from "../Pagination.vue";

    export default {
        name: "SortingCenterBrowser",
        components: {Pagination},
        created() {
            this.getEntities();
        },
        watch: {
            filter: {
                handler() {
                    this.getEntities();
                },
                deep: true
            }
        },
        data() {
            return {
                entities: [],
                pagination: {
                    currentPage: 1,
                    totalElements: 0,
                    pageSize: 20,
                },
                filter: {

                },
                tableFields: [
                    {
                        key: 'label',
                        label: 'Название'
                    }, {
                        key: 'login',
                        label: 'Логин'
                    }, {
                        key: 'address',
                        label: 'География',
                        formatter: value => this.$root.cityOrSettlement(value)
                    }, {
                        key: 'address',
                        label: 'Адрес',
                        formatter: value => this.$root.formatAddress(value)
                    }, {

                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        methods: {
            getEntities() {
                this.$http.get(this.$root.apiUrl + '/api/sorting-centers',
                    {
                        params: {
                            page: this.pagination.currentPage - 1,
                            size: this.pagination.pageSize,
                            filter: JSON.stringify([

                            ])
                        },
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        this.entities = response.body.list;
                        this.pagination.totalElements = response.body.pagination.totalElements;
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            },
            remove(id) {
                this.$http.delete(this.$root.apiUrl + '/api/sorting-center/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success)
                            this.getEntities();
                        else
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            paginationChange(pagination) {
                this.pagination.currentPage = pagination.page;
                this.pagination.pageSize = pagination.size;
                this.getEntities();
            },
            translateStatus(status) {
                let tr = this.statusOptions.find(value => value.value === status);
                if (tr)
                    return tr.text;
                else
                    return status;
            },
        }
    }
</script>

<style scoped>

</style>